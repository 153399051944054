import { Pipe, PipeTransform } from '@angular/core';
import { ShippingAddress } from '@lobos/common-v3';
import { AddressHelperService } from '../services/address/address-helper.service';

@Pipe({
  name: 'shippingAddressList',
  standalone: true,
})
export class ShippingAddressListPipe implements PipeTransform {
  constructor(private addressHelperService: AddressHelperService) {
  }

  public transform(addresses: ShippingAddress[] | null | undefined): {
    key: string | number;
    value: string
  }[] {
    const normalizedAddresses: {
      key: string | number;
      value: string
    }[] = [];
    if (!addresses?.length) {
      return normalizedAddresses;
    }

    for (const address of addresses) {
      normalizedAddresses.push({
        key: address.lngAddressID,
        value: this.addressHelperService.summary(address),
      });
    }

    return normalizedAddresses;
  }
}
